export const ARCHIVED_CANVASES_COUNT_SET = 'ARCHIVED_CANVASES_COUNT_SET';
export const DASHBOARD_CANVAS_SEARCH_ACTIVE_TOGGLE = 'DASHBOARD_CANVAS_SEARCH_ACTIVE_TOGGLE';
export const DASHBOARD_CANVAS_SEARCH_TERM_SET = 'DASHBOARD_CANVAS_SEARCH_TERM_SET';
export const DASHBOARD_CANVAS_STATUS_TYPE_SET = 'DASHBOARD_CANVAS_STATUS_TYPE_SET';
export const DASHBOARD_CANVASES_BY_NAME_SORT = 'DASHBOARD_CANVASES_BY_NAME_SORT';
export const DASHBOARD_CANVASES_BY_RECENCY_SORT = 'DASHBOARD_CANVASES_BY_RECENCY_SORT';
export const DASHBOARD_EXPANDED_FOLDER_ID_ADD = 'DASHBOARD_EXPANDED_FOLDER_ID_ADD';
export const DASHBOARD_EXPANDED_FOLDER_ID_REMOVE = 'DASHBOARD_EXPANDED_FOLDER_ID_REMOVE';
export const DASHBOARD_FILTERED_CANVASES_SET = 'DASHBOARD_FILTERED_CANVASES_SET';
export const DASHBOARD_FILTERS_SET = 'DASHBOARD_FILTERS_SET';
export const DASHBOARD_FOLDERS_NESTED_LIST_SET = 'DASHBOARD_FOLDERS_NESTED_LIST_SET';
export const DASHBOARD_SELECTED_FOLDER_SET = 'DASHBOARD_SELECTED_FOLDER_SET';
export const DASHBOARD_SIDEBAR_WIDTH_SET = 'DASHBOARD_SIDEBAR_WIDTH_SET';
export const DASHBOARD_TEAM_INVITE_SET = 'DASHBOARD_TEAM_INVITE_SET';
export const DASHBOARD_VISITED_FOLDER_ID_ADD = 'DASHBOARD_VISITED_FOLDER_ID_ADD';
export const FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_DELETE = 'FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION DELETE';
export const FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_SET = 'FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_SET';
export const MODAL_CANVAS_ENTER_CREDENTIALS_HIDE = 'MODAL_CANVAS_ENTER_CREDENTIALS_HIDE';
export const MODAL_CANVAS_ENTER_CREDENTIALS_SHOW = 'MODAL_CANVAS_ENTER_CREDENTIALS_SHOW';
export const MODAL_CANVAS_EXPIRY_HIDE = 'MODAL_CANVAS_EXPIRY_HIDE';
export const MODAL_CANVAS_EXPIRY_SHOW = 'MODAL_CANVAS_EXPIRY_SHOW';
export const MODAL_CANVAS_REMINDERS_HIDE = 'MODAL_CANVAS_REMINDERS_HIDE';
export const MODAL_CANVAS_REMINDERS_SHOW = 'MODAL_CANVAS_REMINDERS_SHOW';
export const MODAL_CREATE_EMAIL_CANVAS_HIDE = 'MODAL_CREATE_EMAIL_CANVAS_HIDE';
export const MODAL_CREATE_EMAIL_CANVAS_SHOW = 'MODAL_CREATE_EMAIL_CANVAS_SHOW';
export const MODAL_CREATE_WEBSITE_CANVAS_HIDE = 'MODAL_CREATE_WEBSITE_CANVAS_HIDE';
export const MODAL_CREATE_WEBSITE_CANVAS_SHOW = 'MODAL_CREATE_WEBSITE_CANVAS_SHOW';
export const MODAL_DELETE_FOLDER_HIDE = 'MODAL_DELETE_FOLDER_HIDE';
export const MODAL_DELETE_FOLDER_SHOW = 'MODAL_DELETE_FOLDER_SHOW';
export const MODAL_DESIGN_CANVAS_UPLOAD_HIDE = 'MODAL_DESIGN_CANVAS_UPLOAD_HIDE';
export const MODAL_DESIGN_CANVAS_UPLOAD_SHOW = 'MODAL_DESIGN_CANVAS_UPLOAD_SHOW';
export const MODAL_EDIT_CANVAS_HIDE = 'MODAL_EDIT_CANVAS_HIDE';
export const MODAL_EDIT_CANVAS_SHOW = 'MODAL_EDIT_CANVAS_SHOW';
export const MODAL_EDIT_FOLDER_HIDE = 'MODAL_EDIT_FOLDER_HIDE';
export const MODAL_EDIT_FOLDER_SHOW = 'MODAL_EDIT_FOLDER_SHOW';
export const MODAL_MOVE_CANVAS_TO_FOLDER_HIDE = 'MODAL_MOVE_CANVAS_TO_FOLDER_HIDE';
export const MODAL_MOVE_CANVAS_TO_FOLDER_SHOW = 'MODAL_MOVE_CANVAS_TO_FOLDER_SHOW';
export const MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_HIDE = 'MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_HIDE';
export const MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_SHOW = 'MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_SHOW';
export const MODAL_SET_FEEDBACK_DEADLINE_HIDE = 'MODAL_SET_FEEDBACK_DEADLINE_HIDE';
export const MODAL_SET_FEEDBACK_DEADLINE_SHOW = 'MODAL_SET_FEEDBACK_DEADLINE_SHOW';
export const MODAL_SHARE_FOLDER_HIDE = 'MODAL_SHARE_FOLDER_HIDE';
export const MODAL_SHARE_FOLDER_SHOW = 'MODAL_SHARE_FOLDER_SHOW';
export const MODAL_SWITCH_CANVAS_TO_PREMIUM_HIDE = 'MODAL_SWITCH_CANVAS_TO_PREMIUM_HIDE';
export const MODAL_SWITCH_CANVAS_TO_PREMIUM_SHOW = 'MODAL_SWITCH_CANVAS_TO_PREMIUM_SHOW';
export const MODAL_TEAM_INVITE_HIDE = 'MODAL_TEAM_INVITE_HIDE';
export const MODAL_TEAM_INVITE_SHOW = 'MODAL_TEAM_INVITE_SHOW';
export const MODAL_TRIAL_EXPIRED_HIDE = 'MODAL_TRIAL_EXPIRED_HIDE';
export const MODAL_TRIAL_EXPIRED_SHOW = 'MODAL_TRIAL_EXPIRED_SHOW';
